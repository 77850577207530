import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"team"} />
		<Helmet>
			<title>
				BrightFuture English School
			</title>
			<meta name={"description"} content={"Ми пропонуємо широкий вибір курсів: загальна англійська для початківців і просунутих, бізнес-англійська, підготовка до міжнародних іспитів, англійська для дітей та підлітків. "} />
			<meta property={"og:title"} content={"BrightFuture English School"} />
			<meta property={"og:description"} content={"Ми пропонуємо широкий вибір курсів: загальна англійська для початківців і просунутих, бізнес-англійська, підготовка до міжнародних іспитів, англійська для дітей та підлітків. "} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1538708591342-2dcd6706e3b6?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.HeaderNew>
			<Override slot="text" />
			<Override slot="quarklycommunityKitMobileSidePanel" />
			<Override slot="text1" />
		</Components.HeaderNew>
		<Section padding="80px 0 80px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 1rem 0px"
					color="--dark"
					text-align="center"
					font="--headline1"
					opacity="1"
					width="100% border-box"
					xl-text-transform="initial"
				>
					Послуги
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
				margin="0px 0px .7rem 0px"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1543109740-4bdb38fda756?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://images.unsplash.com/photo-1543109740-4bdb38fda756?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1543109740-4bdb38fda756?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1543109740-4bdb38fda756?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1543109740-4bdb38fda756?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1543109740-4bdb38fda756?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1543109740-4bdb38fda756?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1543109740-4bdb38fda756?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Загальна англійська
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Наш курс загальної англійської мови призначений для студентів різного рівня підготовки: від початківців до просунутих. Програма курсу включає розвиток усіх мовних навичок: говоріння, слухання, читання та письма. Заняття проводяться у формі інтерактивних уроків з використанням сучасних навчальних матеріалів.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
					position="relative"
				>
					<Image
						src="https://images.unsplash.com/photo-1506784926709-22f1ec395907?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						srcSet="https://images.unsplash.com/photo-1506784926709-22f1ec395907?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1506784926709-22f1ec395907?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1506784926709-22f1ec395907?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1506784926709-22f1ec395907?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1506784926709-22f1ec395907?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1506784926709-22f1ec395907?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1506784926709-22f1ec395907?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Бізнес-англійська
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Курс бізнес-англійської розроблений для тих, хто хоче покращити свої мовні навички для роботи в міжнародному середовищі. Програма включає вивчення спеціалізованої лексики, розвиток навичок ведення переговорів, написання ділових листів та презентацій. Ми допоможемо вам стати впевненим у спілкуванні з іноземними партнерами.
						</Text>
					</Box>
				</Box>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="center"
				justify-content="center"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1503676260728-1c00da094a0b?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Підготовка до міжнародних іспитів
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Ми пропонуємо курси підготовки до міжнародних іспитів, таких як IELTS, TOEFL, Cambridge English. Наші викладачі мають великий досвід підготовки студентів до цих іспитів та знають усі тонкощі та вимоги кожного з них. Програма курсу включає інтенсивні заняття, моделювання іспитів та індивідуальні консультації.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1629992101753-56d196c8aabb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://images.unsplash.com/photo-1629992101753-56d196c8aabb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1629992101753-56d196c8aabb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1629992101753-56d196c8aabb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1629992101753-56d196c8aabb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1629992101753-56d196c8aabb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1629992101753-56d196c8aabb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1629992101753-56d196c8aabb?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Англійська для дітей та підлітків
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Ми пропонуємо курси англійської мови для дітей та підлітків. Наші програми розроблені з урахуванням вікових особливостей та інтересів учнів. Заняття проходять у формі ігор, творчих завдань та інтерактивних уроків, що робить навчання цікавим та захоплюючим. Ми допоможемо вашим дітям полюбити англійську мову та досягти успіхів у навчанні.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://images.unsplash.com/photo-1588702547919-26089e690ecc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://images.unsplash.com/photo-1588702547919-26089e690ecc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1588702547919-26089e690ecc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1588702547919-26089e690ecc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1588702547919-26089e690ecc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1588702547919-26089e690ecc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1588702547919-26089e690ecc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1588702547919-26089e690ecc?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--headline3">
							Онлайн-курси
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Ми пропонуємо можливість вивчати англійську мову онлайн. Наші онлайн-курси розроблені з урахуванням потреб сучасних студентів, які цінують свій час та шукають зручні формати навчання. Ви можете обирати між індивідуальними заняттями та груповими уроками, при цьому отримуючи якісну освіту та підтримку викладачів.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Faq padding="0 0 60px 0">
			<Override slot="text1">
				Чи можна змінити розклад занять?
			</Override>
			<Override slot="text2">
				Ми завжди йдемо назустріч нашим студентам і намагаємося зробити розклад занять максимально зручним для них. Якщо вам потрібно змінити розклад, зверніться до нашого менеджера, і ми спробуємо знайти найбільш підходящий варіант. Однак, варто зазначити, що зміна розкладу можлива за умови наявності вільних місць у групах.
			</Override>
			<Override slot="text3">
				Які методи оплати доступні?
			</Override>
			<Override slot="text4">
				Ми пропонуємо різні методи оплати для зручності наших студентів. Ви можете сплатити за курс готівкою, банківським переказом або за допомогою платіжних систем онлайн. Детальну інформацію про методи оплати ви можете отримати у наших менеджерів або на сайті у розділі "Оплата".
				<br />
				<br />
			</Override>
			<Override slot="text5">
				Чи є у вас пробні заняття?
			</Override>
			<Override slot="text6">
				Так, ми пропонуємо пробні заняття для всіх нових студентів. Це дає вам можливість ознайомитися з нашими методиками навчання, викладачами та загальною атмосферою школи. Пробне заняття допомагає визначити ваш рівень володіння англійською мовою та обрати найбільш відповідний курс. Зв'яжіться з нашими менеджерами, щоб записатися на пробне заняття та отримати відповіді на всі ваші запитання. Пробне заняття безкоштовне і не зобов'язує вас до подальшого навчання, але допоможе прийняти обґрунтоване рішення про вступ до нашої школи.
				<br />
			</Override>
		</Components.Faq>
		<Components.NewFooter1>
			<Override slot="link1">
				0969991469
			</Override>
		</Components.NewFooter1>
	</Theme>;
});